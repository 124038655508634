<template>
  <validation-observer ref="validationObserver">
    <b-card-form>
      <!-- Milestones -->
      <div v-for="(milestone, milestoneIndex) in milestones" :key="milestoneIndex" class="mb-3">
          <b-row
              :id="milestone.id"
              ref="row"
              class="w-100 ml-0 mb-3 flex-column flex-lg-row"
          >
            <b-col>
              <b-form
                  :ref="`form-${milestoneIndex}`"
                  :style="{ height: trHeight, 'border': 'solid 1px #D8D6DD' }"
                  class="repeater-form px-1 pt-1 pb-2 pl-lg-2 pr-lg-4 pt-lg-3 "
                  @submit.prevent=""
              >
                <div class="d-flex flex-wrap flex-lg-nowrap">
                  <b-col sm="8" lg="2" class="mr-lg-3 mt-0 mb-1 mb-lg-0 p-0 text-lg-right font-weight-bold text-dark" style="margin-top: 7px">Template Name:</b-col>
                  <b-form-group class="title w-100">
                    <validation-provider
                        #default="{ errors }"
                        :vid="'templateName' + milestone.id"
                        name="Template Name"
                        rules="required|alphaNumSpaces"
                    >
                      <b-form-input
                          id="templateName"
                          v-model="milestone.title"
                          type="text"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Template Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="d-flex flex-wrap flex-lg-nowrap mt-1">
                  <b-col sm="8" lg="2" class="mr-lg-3 mt-0 mb-1 mb-lg-0 p-0 text-lg-right font-weight-bold text-dark" style="margin-top: 7px">Min Budget threshold($):</b-col>
                  <b-form-group class="w-100">
                    <validation-provider
                        #default="{ errors }"
                        :vid="'minBudget' + milestone.id"
                        name="Min Budget"
                        rules="required|numeric|minValue:70"
                    >
                      <b-form-input
                          id="minBudget"
                          v-model="milestone.priceFrom"
                          type="text"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Min Budget threshold"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <!-- Stages -->
                <div v-for="(stage, stageIndex) in milestone.stages" :key="stageIndex" class="mt-1">
                  <div class="d-flex flex-wrap flex-lg-nowrap stage" @mouseover="handleMouseOver(milestoneIndex, stage.id)" @mouseleave="handleMouseOut">
                    <b-col sm="12" lg="2" class="mr-lg-3 mb-1 mb-lg-0 p-0 text-lg-right font-weight-bold text-dark d-flex d-lg-block justify-content-between">
                      <span>{{ `Milestone  ${stageIndex + 1}:` }}</span>
                      <feather-icon
                          v-if="hoveredStage === stage"
                          class="text-danger d-lg-none"
                          style="margin: 3px 7px 0 0"
                          icon="XIcon"
                          size="20"
                          @click="removeStage(milestoneIndex, stageIndex)"
                      />
                    </b-col>

                    <!-- Number Of Revisions -->
                    <b-form-group label="Max Number of revisions" label-for="numOfRevisions" class="w-100 mr-0 mr-lg-2">
                      <validation-provider
                          #default="{ errors }"
                          :vid="'numberOfRevisions' + stage.id"
                          name="Number Of Revisions"
                          rules="numeric|minValue:0|maxValue:10"
                      >
                        <b-form-input
                            id="numOfRevisions"
                            type="text"
                            disabled
                            :state="errors.length > 0 ? false : null"
                            placeholder="Number Of Revisions"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- / Number Of Revisions -->

                    <!-- Amount -->
                    <b-form-group label="Amount(%)" label-for="amount" class="w-100">
                      <validation-provider
                          #default="{ errors }"
                          :vid="'amount' + stage.id"
                          name="Amount"
                          rules="required|numeric|minValue:0|maxValue:100"
                      >
                        <b-form-input
                            id="amount"
                            v-model="stage.percentage"
                            type="text"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Amount"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- / Amount -->

                    <feather-icon
                        v-if="hoveredStage === stage"
                        class="text-danger ml-1 d-none d-lg-block"
                        style="margin-top: 30px"
                        icon="XIcon"
                        size="20"
                        @click="removeStage(milestoneIndex, stageIndex)"
                    />
                  </div>
                </div>
                <!-- / Stages -->

                <b-row class="px-1 mt-1">
                  <b-button variant="outline-primary" @click="addStage(milestoneIndex, milestone.stages.length)" class="text-nowrap px-1 px-md-2 w-100">
                    <feather-icon
                        icon="PlusIcon"
                    />
                    <span>
                      Add New Milestone
                    </span>
                  </b-button>
                </b-row>

                <b-row class="px-1 mt-1 justify-content-md-end" v-if="isMilestoneEdited(milestoneIndex)">
                  <b-button variant="primary" @click="save(milestone)" class="w-100">
                    Save Changes
                  </b-button>
                </b-row>
              </b-form>
            </b-col>
          </b-row>

            <hr v-if="milestoneIndex !== milestones.length - 1">
          </div>
    </b-card-form>
  </validation-observer>
</template>

<script>
import BCardForm from '@/components/b-card-form';
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BButton,
  BFormFile,
  BImg,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import UUID from '@/libs/uuid'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, numeric, minValue, maxValue, alphaNumSpaces } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormFile,
    BCardForm,
    BImg
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      isLoading: false,
      milestones: [],
      originalMilestones: [],
      hoveredStage: null,
      changedMilestones: [],
      required,
      numeric,
      minValue,
      maxValue,
      alphaNumSpaces
    }
  },

  methods: {
    fetchMilestones() {
      this.$http.get('/milestones')
          .then(response => {
            this.milestones = response.data;
            this.originalMilestones = JSON.parse(JSON.stringify(response.data));
          })
    },

    handleMouseOver(milestoneIndex, stageId) {
      const stage = this.milestones[milestoneIndex].stages?.find(stage => stage.id === stageId);
      if (stage) {
        this.hoveredStage = stage;
      }
    },

    handleMouseOut() {
      this.hoveredStage = null;
    },

    removeStage(index, stageOrder) {
      this.milestones[index].stages?.splice(stageOrder, 1);
      this.changedMilestones[index] = true;
    },

    addStage(index, order) {
      this.milestones[index].stages?.splice(order, 0, {id: UUID(), percentage: 0, order: order + 1});
      this.changedMilestones[index] = true;
    },

    isMilestoneEdited(index) {
      const originalMilestone = this.originalMilestones[index];
      const currentMilestone = this.milestones[index];

      if (+originalMilestone.priceFrom !== +currentMilestone.priceFrom) return true;

      if (originalMilestone.title !== currentMilestone.title) return true;

      if (originalMilestone.stages?.length !== currentMilestone.stages?.length) return true;

      const originalStages = originalMilestone?.stages;
      const currentStages = currentMilestone?.stages;

      for (let i = 0; i < originalStages.length; i++) {
        if (+originalStages[i].percentage !== +currentStages[i].percentage) return true;
      }

      return false;
    },

    save(milestone) {
      const valid = this.$refs.validationObserver
          .validate()
          .then(success => {
            if (success) {
              this.$http.post(`/milestones/${milestone.id}/update?PriceFrom=${milestone.priceFrom}`, {
                title: milestone.title,
                addedStages: milestone.stages
              })
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Save Successfully',
                        icon: 'CheckCircleIcon',
                        variant: 'success',
                      },
                    });

                    this.fetchMilestones();
                  })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Check the fields validations above to be able to save!',
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              })
            }
            return success
          })
      return valid
    },
  },

  mounted() {
    this.fetchMilestones();
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.stage {
  padding: 5px 0;
  transition: background-color ease-in-out 0.2s;
  cursor: pointer;

  &:hover {
    background-color: #F8F8F9;
  }
}

@media screen and (min-width: 992px) {
  .form-group:not(.title), button {
    width: fit-content !important;
  }
}
</style>
